
import { Options, Vue } from 'vue-class-component';
import { AdvCampaignClient, ExpertClient, ImpressionClickedClient, ImpressionViewedClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({})
export default class advCampaignsList extends Vue {

    items: OM.AdvCampaign[] = [];

    views: { [key: string]: number } = {};
    clicks: { [key: string]: number } = {};

    created(){
        AdvCampaignClient.getAll()
        .then(x => {
            this.items = x;
            this.items.forEach(x => {
                ImpressionViewedClient.countByCampaign(x.identifier, false)
                .then(c => this.views[x.identifier] = c)
                ImpressionClickedClient.countByCampaign(x.identifier, false)
                .then(c => this.clicks[x.identifier] = c)
            })
        })
    }

}
